<template>
  <div class="work-page max-width" v-if="currentWorkPage">
    <a :href="currentWorkPage.url" target="_blank">
      <vue-image
        :source="`.${currentWorkPage.logo}`"
        :width="currentWorkPage.logoWidth"
        :height="currentWorkPage.logoHeight"
        :alt="currentWorkPage.name"
        :disable-aspect-ratio="true"
      ></vue-image>
      <h1 v-if="currentWorkPage.showName">{{ currentWorkPage.name }}</h1>
      <p>{{ currentWorkPage.url.replace('http://', '').replace('https://', '').replace('/', '')  }}</p>
    </a>
    <p v-for="(description, index) in currentWorkPage.descriptions" :key="index" v-html="description"></p>
    <p class="technologies">
      <strong>{{ currentWorkPage.technologiesTitle }}</strong>:
      <span v-for="(tech, i) in currentWorkPage.technologies" :key="i">
        <a :href="tech.link" target="_blank">{{ tech.name }}</a>
        <span v-if="i < currentWorkPage.technologies.length - 1">, </span>
      </span>
    </p>
    <div class="prev-next">
      <div class="prev">
        <div v-if="prevPage" @click="$router.push(`/work/${prevPage.id}`)">
          <p>Prev.</p>
          <a>{{ prevPage.name }}</a>
        </div>
      </div>
      <div class="next">
        <div v-if="nextPage" @click="$router.push(`/work/${nextPage.id}`)">
          <p>Next</p>
          <a>{{ nextPage.name }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { setMeta } from '../helpers/meta/setMeta'

export default {
  name: 'Work',
  data () {
    return {
      currentWorkPage: false,
      prevPage: false,
      nextPage: false
    }
  },

  computed: {
    ...mapState('work', [
      'work'
    ])
  },

  mounted () {
    this.setCurrentWorkPage()
    this.$nextTick(this.orderWorkList)
  },

  methods: {
    setCurrentWorkPage () {
      this.currentWorkPage = this.work.find((job, index) => {
        const isJob = job.id === this.$router.currentRoute.params.id
        if (isJob) {
          this.prevPage = this.work[index - 1]
          this.nextPage = this.work[index + 1]

          return isJob
        }
      })
      setMeta(this.currentWorkPage)
    },

    orderWorkList () {
      var workList = document.querySelector('.split')
      if (workList) {
        workList.style.setProperty('grid-auto-flow', 'column')
        workList.style.setProperty('grid-template-rows', `repeat(${Math.round(workList.childElementCount / 2)},1fr)`)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.work-page {
  /deep/ img, /deep/ svg, > a {
    transition: opacity 0.1s;
  }

  /deep/ img:not(.full-width), /deep/ svg {
    display: block;
    margin: 0 auto 1em;
    max-width: 250px !important;
    max-height: 150px !important;
    height: auto !important;
    width: auto !important;
  }

  > h1, > a {
    text-align: center;
  }

  > h1 {
    text-decoration: none;
  }

  > a {
    display: block;
    margin-bottom: 3em;

    p {
      text-decoration: underline;
    }

    &:hover {
      &, /deep/ img {
        opacity: 0.5;
      }
    }
  }

  p /deep/ ul.split {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .technologies {
    font-size: 0.9em;
  }

  .prev-next {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin-top: 2.5em;

    > div {
      flex-basis: 47%;

      &:last-of-type {
        text-align: right;
      }

      div {
        // text-decoration: none;

        p {
          line-height: 1;
          font-size: 0.8em;
          margin: 0;
          cursor: pointer;
        }

        a {
          font-weight: bold;
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }
}
</style>
