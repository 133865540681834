<template>
  <div class="contact max-width">
    <h1>Contact Me</h1>
    <p>
      Although I work full time at Electric Eye, I still have room to work on freelance projects.
      If you'd like to talk about your next project, or just shoot the shit,
      fill out the form below, or
      <a href="mailto:mjweaver01@gmail.com">email me directly</a>.
    </p>
    <form id="ct-form" action="https://formspree.io/f/mjweaver01@gmail.com" method="POST" @submit="setLoading()">
      <input id="ct-name" type="text" name="name" placeholder="Name" required="">
      <input id="ct-email" type="email" name="_replyto" placeholder="Email" required="">
      <input id="ct-subject" name="_subject" type="text" placeholder="Subject" required="">
      <textarea id="ct-message" rows="3" placeholder="Leave a Message" name="message" required=""></textarea>
      <input type="text" name="_gotcha" style="display: none">
      <button class="button" type="submit"  :class="{ 'loading': loading }">Contact Me</button>
    </form>
  </div>
</template>

<script>
export default {
  data () {
    return {
      loading: false
    }
  },

  methods: {
    setLoading () {
      this.loading = true
    }
  }
}
</script>

<style lang="scss" scoped>
.contact {
  input, textarea, button {
    width: 100%;
    display: block;
    margin-bottom: 1em;
  }
}
</style>
