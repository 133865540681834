<template>
  <div class="work max-width">
    <h1>Work</h1>
    <div class="work-wrapper">
      <router-link class="work" v-for="(job, index) in work" :to="`/work/${job.id}`" :key="index">
        <vue-image
          :source='job.logo'
          :width='job.logoWidth'
          :height='job.logoHeight'
          :alt="job.name"
          :disable-aspect-ratio="true"
        ></vue-image>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Work',
  computed: {
    ...mapState('work', [
      'work'
    ])
  }
}
</script>

<style lang="scss" scoped>
.work-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2em;
  grid-row-gap: 4em;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 4em;

  @media(min-width: $bp-xs) {
    grid-column-gap: 4em;
    grid-row-gap: 8em;
    grid-template-columns: 1fr 1fr 1fr;
  }

  /deep/ img:not(.full-width), /deep/ svg {
    max-width: 100% !important;
    max-height: 150px !important;
    width: auto !important;
    margin: 0 auto;
    transition: opacity 0.2s;

    &:hover {
      opacity: 0.5;
    }
  }
}
</style>
