<template>
  <div class="wrapper max-width">
    <h1>Photography</h1>
    <div class="photo-wrapper">
      <div v-show="!imagesLoaded" class="images-wrapper">
        <vue-image
          :key="i"
          v-for="i in 21"
          :width="width"
          :height="height"
        ></vue-image>
      </div>
      <div v-show="imagesLoaded" class="images-wrapper">
        <vue-image
          :key="index"
          v-for="(image, index) in images"
          @click.native="viewImageInModal(createDirectLink(image), index)"
          @mouseover.native="loadFullImage(image)"
          v-show="imagesLoaded && images.length > 0"
          :source="createThumbnailLink(image)"
          :width="width"
          :height="height"
          :alt="`Mike Weaver Photography Image ${index}`"
        ></vue-image>
      </div>
      <div tabindex="0" id="imageModalWrapper" style="display: none" class="modalWrapper" v-show="showImageModal" @keyup.left="changeModalImage(imageModalData.index, 'prev')" @keyup.right="changeModalImage(imageModalData.index, 'next')" @keyup.esc="toggleImageModal()">
        <div class="close" @click="toggleImageModal()"><i class='fa fa-close'></i></div>
        <div class="overlay" @click="toggleImageModal()"></div>
        <div class="modal">
          <div class="prev" @click="changeModalImage(imageModalData.index, 'prev')"><i class='fa fa-angle-left'></i></div>
          <div class="next" @click="changeModalImage(imageModalData.index, 'next')"><i class='fa fa-angle-right'></i></div>
          <img v-bind:src="imageModalData.image" />
        </div>
      </div>
      <div id="imageLoaderDock" style="display: none"></div>
      <p class="fin" v-show="imagesLoaded && allImagesLoaded">~ FIN. ~</p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  mounted () {
    this.getResource()
  },

  computed: {
    ...mapState('photography', [
      'storeImages'
    ])
  },

  data () {
    return {
      width: 500,
      height: 333,
      key: 'AIzaSyDorJpnMrA_9NeF-9QxPM80pPesTvANJAQ',
      images: [],
      imagesStore: [],
      imageStoreIndex: 0,
      folderId: '1WQvOpxKTAj-DGv-d5f1yUX4LK59VDEP-',
      showImageModal: false,
      imagesLoaded: false,
      allImagesLoaded: false,
      imageModalData: {},
      loadImagesTimeout: 0,
      loadImagesIfShortTimeout: 0,
      scrollX: 0,
      scrollY: 0
    }
  },

  methods: {
    async getResource () {
      if (this.folderId.length <= 0 || this.folderId === '') {
        this.response = 'Please paste a Google Folder ID!'
        return
      }

      let alreadyFetched = false
      if (this.storeImages.length === 0) {
        let url = "https://www.googleapis.com/drive/v3/files?q='" + this.folderId + "'+in+parents&key=" + this.key + '&pageSize=1000'
        await this.$store.dispatch('photography/getImages', url)
      } else {
        alreadyFetched = true
      }

      const chunk = 10 // segment out images into 8-image chunks

      if (alreadyFetched) {
        this.images = this.storeImages
        this.imagesLoaded = true
        this.allImagesLoaded = true
      } else {
        for (let i = 0; i < this.storeImages.length; i += chunk) {
          this.imagesStore.push(this.storeImages.slice(i, i + chunk))
        }

        this.images = this.imagesStore[this.imageStoreIndex]
        this.imageStoreIndex++
        this.imagesLoaded = true
        this.startScrollWatcher()
      }
    },

    createThumbnailLink (file) {
      return `https://drive.google.com/thumbnail?id=${file.id}&sz=w500-h500`
    },

    createDirectLink (file) {
      // FULL SIZE IMAGE
      // return `https://drive.google.com/uc?export=download&id=${file.id}`

      // MAX IT OUT AT 1500 FOR PERFORMANCE
      return `https://drive.google.com/thumbnail?id=${file.id}&sz=w1500-h1500`
    },

    loadFullImage (image) {
      let newImg = document.createElement('img')
      newImg.src = this.createDirectLink(image)
      newImg.id = image.id

      var element = document.getElementById(image.id)
      if (typeof element === 'undefined' || element === null) {
        document.getElementById('imageLoaderDock').appendChild(newImg)
      }
    },

    viewImageInModal (image, index) {
      this.$set(this.imageModalData, 'image', image)
      this.$set(this.imageModalData, 'index', index)
      this.loadEitherSideImages(index)
      this.toggleImageModal(true)
    },

    toggleImageModal (show) {
      if (show) {
        this.showImageModal = true
      } else {
        this.showImageModal = !this.showImageModal
      }

      if (this.showImageModal === true) {
        this.$nextTick(() => {
          // workaround for modalWrapper focus is to save scroll position, focus, and scrollTo same position
          let x = window.scrollX; let y = window.scrollY
          document.getElementById('imageModalWrapper').focus()
          window.scrollTo(x, y)
        })
      }
    },

    changeModalImage (index, direction) {
      let nextImage = null
      let imagesLength = this.images.length

      if (direction === 'prev') {
        nextImage = index - 1
      } else {
        nextImage = index + 1
      }

      if (nextImage >= imagesLength) {
        nextImage = 0
      } else if (nextImage < 0) {
        nextImage = imagesLength - 1
      }

      this.viewImageInModal(this.createDirectLink(this.images[nextImage]), nextImage)
      this.loadEitherSideImages(nextImage)
    },

    loadEitherSideImages (index) {
      let prev = index - 1
      let next = index + 1

      if (next > this.images.length) {
        next = 0
      }

      if (prev < 0) {
        prev = this.images.length - 1
      }

      this.loadFullImage(this.images[prev])
      this.loadFullImage(this.images[next])
    },

    startScrollWatcher () {
      document.addEventListener('scroll', this.scrollMethod)
      document.addEventListener('touchmove', this.scrollMethod)

      let event = new Event('scroll')
      document.dispatchEvent(event)
    },

    scrollMethod () {
      let ticking = false

      if (!ticking) {
        window.requestAnimationFrame(() => {
          ticking = false

          if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight * 0.5)) {
            this.loadMoreImages()
          }
        })

        ticking = true
      }
    },

    loadMoreImages () {
      clearTimeout(this.loadImagesTimeout)
      this.loadImagesTimeout = setTimeout(() => {
        if (!this.imagesStore[this.imageStoreIndex]) {
          this.allImagesLoaded = true
          return
        }

        for (let i = 0; i < this.imagesStore[this.imageStoreIndex].length; i++) {
          this.images.push(this.imagesStore[this.imageStoreIndex][i])
        }

        this.imageStoreIndex++
        this.loadImagesIfShortTimeout = setTimeout(() => {
          if (document.body.offsetHeight < window.innerHeight) {
            this.loadMoreImages()
          }
        }, 100)
      }, 100)
    }
  }
}
</script>

<style lang="scss" scoped>
@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(359deg);
  }
}

.photo-wrapper {
  margin: 0 auto;

  .loading {
    width: 36px;
    height: 36px;
    margin: 18px;
    transform: rotate(0deg);
    animation: spin .6s infinite linear;
    border: 4px solid $white;
    border-top: 4px solid $black;
    border-radius: 50%;
    stroke: $white;
  }

  .images-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1em;
    margin: 0 -0.5em;

    @media(max-width: $bp-xs) {
      grid-template-columns: 1fr;
    }

    /deep/ .vue-image {
      display: block;
      cursor: pointer;
      transition: all 0.2s;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .fin {
    font: 100 italic 1em $font-family;
    text-align: center;
    margin-bottom: 1em;
  }

  .modalWrapper {
    position: relative;

    .close {
      position: fixed;
      top: 0;
      right: 0;
      padding: 1em;
      color: white;
      font-size: 1.5em;
      z-index: 6;
      cursor: pointer;
    }

    .overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,0.8);
      z-index: 4;
      animation: fadeIn 0.25s;
    }

    .modal {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-height: calc(100% - 2em);
      animation: openUp 0.1s;
      z-index: 5;
      backface-visibility: hidden;
      animation: fadeIn 0.25s;

      img {
        max-width: 85vw;
        max-height: 85vh;

        @media(max-width: $bp-xs) {
          max-width: 75vw;
        }
      }

      .prev, .next {
        position: absolute;
        color: white;
        font-size: 2em;
        cursor: pointer;
        top: 50%;
        transform: translateY(-50%);
        padding: 1em;
        user-select: none;
        z-index: 5;
      }

      .prev {
        left: -2em;
      }

      .next {
        right: -2em;
      }
    }
  }
}
</style>
