<template>
  <div class="about max-width">
    <div>
      <vue-image
        :source="require(`@/assets/me.jpg`)"
        :width='736'
        :height='482'
        :alt="'Mike Weaver'"
      ></vue-image>
    </div>
    <div>
      <h1>Hey, I'm Mike.</h1>
      <p>A battle-hardened web development expert, I have almost a decade of experience in all things UI/UX. I currently work as a <i>Full Stack Developer</i> at <strong>Triple Whale 🐳</strong>, building the next generation of e-commerce tools.</p>
      <p>I enjoy architecting and implementing new technologies, providing solutions to complex business challenges, and being comfortable in the uncomfortable.</p>
      <p>When I'm not coding, I spend my time reading, taking photos, discovering new hiking trails, riding my motorcycle, and hunting for new records to add to my collection.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'About'
}
</script>

<style lang="scss" scoped>
.about {
  > div {
    &:last-of-type {
      margin-top: 2em;
    }
  }

  /deep/ img {
    width: 100%;
  }
}
</style>
